import {useEffect, useState} from "react";

import Loading from "../Loading";

import PageContentContainer from "../PageContentContainer";

import {API_URL, callApi} from "../../utils";

import "../../../css/results/poll-completion-results.css";

const PollStats = props => {
    let stats = props.stats;

    return (
        <span className="list-group-item mb-3">
            <h4 className="mb-1 fw-bold text">
                "{props.name}"
            </h4>

            <p className="mb-1">
                {stats.started}/{stats.total} rozpoczęte ({stats.completed}/{stats.started} zakończone)
            </p>
            
            <small>
                {stats.total - stats.started}/{stats.total} nierozpoczęte
            </small>
        </span>
    );
  };

const PollsStats = props => {
    let pollsStats = {};

    Object.values(props.results).forEach(result => {
        let pollName = result.poll_name;

        let pollStats = pollsStats[pollName];

        if(!pollStats)
        {
            pollStats = {
                total: 0,
                started: 0,
                completed: 0
            };

            pollsStats[pollName] = pollStats;
        }

        pollStats.total++;

        if(!result.active)
            return;

        pollStats.started++;

        if(result.finished)
            pollStats.completed++;
    });

    let pollsStatsElements = Object.entries(pollsStats).map(
        ([pollName, pollStats], i) => {
            return (
                <PollStats
                    key={i}
                    name={pollName}
                    stats={pollStats}
                />
            );
        }
    );

    return (
        <div className="list-group polls-stats-container">
            {pollsStatsElements}
        </div>
    );
};

const NavigationMenu = () => {
    return (
        <nav>
            <ul className="nav masthead-nav center">
                <li>
                    <button
                        onClick={
                            () => {
                                // download the report file
                                document.location.href = `${API_URL}/report`;
                            }
                        }
                        className="btn btn-primary mt-4 fw-bold"
                    >
                        Pobierz raport
                    </button>
                </li>
            </ul>
        </nav>
    );
};

const PollCompletionResults = () => {
    const [loaded, setLoaded] = useState(false);
    const [results, setResults] = useState(null);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState(null);

    useEffect(() => {
        callApi(
            "results", {
                method: "GET",
                onError: errorMessage => {
                    setLoaded(true);

                    setLoadingErrorMessage(errorMessage);
                },
                onSuccess: data => {
                    setLoaded(true);

                    setResults(data.results);
                }
            }
        );
    }, []);

    let header = (
        loaded && results &&
        <div className="container inner">
            <h3 className="masthead-brand">
                Statystyki wypełnienia ankiet

                {NavigationMenu()}
            </h3>
        </div>
    );

    return (
        <PageContentContainer
            header={header}
        >
            {
                loaded ? (
                    results ? (
                        <PollsStats
                            results={results}
                        />
                    ) : (
                        <h1 className="text-danger fw-bold">
                            {loadingErrorMessage}
                        </h1>
                    )
                ) : (
                    <Loading/>
                )
            }
        </PageContentContainer>
    );
};

export default PollCompletionResults;
