import {RadioButton, OpenTextAnswer, QuestionHeader, getChoiceElements} from "./QuestionCard";

import "../../../css/poll/question-grid.css";

const Row = props => {
    return (
        <div className="row align-items-center py-2">
            {props.children}
        </div>
    );
};

const ChoicesHeader = props => {
    // add padding at the top left corner of the grid
    // (but only if there is at least one choice, because
    // just one choice means open-text answers, and
    // padding causes the header (choice text) to be misaligned)
    let choices = props.choices;

    if(choices.length > 1)
        choices = ["", ...choices];

    let choiceElements = choices.map((choice, i) => {
        return (
            <div
                key={i}
                className="col text-break"
            >
                {choice}
            </div>
        );
    });

    return (
        <Row>
            {choiceElements}
        </Row>
    );
};

const Question = props => {
    let question = props.question;

    let questionId = question.id;

    let inputName = ["question", props.parentQuestionId, questionId].join("-");

    let choiceElements = getChoiceElements(
        [...Array(props.choicesCount).keys()],
        _ => (
            <OpenTextAnswer
                placeholder="Odpowiedź"
                onAnswered={
                    text => props.onAnswered(questionId, text)
                }
            />
        ),
        (_, i) => (
            <div
                key={i}
                className="col"
            >
                <RadioButton
                    name={inputName}
                    onChange={
                        () => props.onAnswered(questionId, i)
                    }
                />
            </div>
        )
    );

    return (
        <Row>
            <div className="col">
                {question.text}
            </div>

            {choiceElements}
        </Row>
    );
};

const Questions = props => {
    let questions = props.questions;

    let questionElements = questions.map((question, i) => {
        question = {
            id: i,
            text: question
        };

        return (
            <Question
                key={i}
                question={question}
                onAnswered={props.onAnswered}
                choicesCount={props.choicesCount}
                parentQuestionId={props.parentQuestionId}
            />
        );
    });

    return (
        <div>
            {questionElements}
        </div>
    );
};

const QuestionsContainer = props => {
    let choices = props.choices;

    return (
        <div>
            <h5 className="card-header">
                <ChoicesHeader
                    choices={choices}
                />
            </h5>

            <div className="card-body">
                <Questions
                    questions={props.questions}
                    choicesCount={choices.length}
                    onAnswered={props.onAnswered}
                    parentQuestionId={props.parentQuestionId}
                />
            </div>
        </div>
    );
};

const QuestionGrid = props => {
    let question = props.question;

    return (
        <div className="question-grid-container container">
            <div className="card">
                <QuestionHeader
                    text={question.text}
                    required={question.required}
                />

                <QuestionsContainer
                    choices={question.choices}
                    onAnswered={props.onAnswered}
                    parentQuestionId={question.id}
                    questions={question.child_questions}
                />
            </div>
        </div>
    );
};

export default QuestionGrid;
