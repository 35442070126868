import "../../../css/poll/question-card.css";

const RadioButton = props => {
    return (
        <input
            type="radio"
            name={props.name}
            onChange={props.onChange}
        />
    );
};

const ListGroupItem = props => {
    return (
        <li className="list-group-item">
            {props.children}
        </li>
    );
};

const QuestionChoice = props => {
    let inputName = `question-${props.questionId}`;

    return (
        <ListGroupItem>
            <div className="form-check">
                <label>
                    {props.choice}

                    <RadioButton
                        name={inputName}
                        onChange={props.onAnswered}
                    />
                </label>
            </div>
        </ListGroupItem>
    );
};

const OpenTextAnswer = props => {
    return (
        <textarea
            rows="3"
            maxLength="1024"
            className="form-control"
            placeholder={props.placeholder}
            onChange={
                event => props.onAnswered(event.target.value)
            }
        />
    );
};

function getChoiceElements(choices, createOpenTextAnswerElement, createChoiceElement)
{
    let choiceElements = null;

    // if there is only one choice then we assume
    // it's an open-text answer
    if(choices.length === 1)
        choiceElements = createOpenTextAnswerElement(choices[0]);
    else
        choiceElements = choices.map(createChoiceElement);

    return choiceElements;
}

const QuestionChoices = props => {    
    let questionId = props.questionId;

    let choiceElements = getChoiceElements(
        props.choices,
        choice => (
            <ListGroupItem>
                <OpenTextAnswer
                    placeholder={choice}
                    onAnswered={props.onAnswered}
                />
            </ListGroupItem>
        ),
        (choice, i) => (
            <QuestionChoice
                key={i}
                choice={choice}
                questionId={questionId}
                onAnswered={
                    () => props.onAnswered(i)
                }
            />
        )
    );

    return (
        <ul className="list-group">
            {choiceElements}
        </ul>
    );
};

const QuestionHeader = props => {
    return (
        <h3 className="card-header fw-bold">
            {props.text}
            {
                props.required &&
                <span className="text-danger"> *</span>
            }
        </h3>
    );
}

const QuestionCard = props => {
    let question = props.question;

    return (
        <div className="col-md-6">
            <div className="card card-primary">
                <QuestionHeader
                    text={question.text}
                    required={question.required}
                />

                <div className="card-block">
                    <QuestionChoices
                        questionId={question.id}
                        choices={question.choices}
                        onAnswered={props.onAnswered}
                    />
                </div>
            </div>
        </div>
    );
};

export {
    RadioButton,
    QuestionCard,
    OpenTextAnswer,
    QuestionHeader,
    getChoiceElements
};
