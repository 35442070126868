import axios from "axios";

import Cookies from "js-cookie";

import {StatusCodes} from "http-status-codes";

const DEV_ENV = "prod";

const BACKEND_HOSTS = {
    local: "http://localhost:8000",
    prod: "https://ankieta.przymus.dev"
};

const API_URL = [BACKEND_HOSTS[DEV_ENV], "api", "poll"].join("/");

function callApi(apiPath, {method, data, onSuccess, onError})
{
    axios(
        apiPath + "/", {
            data: data,
            method: method,
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken")
            }
        }
    )
    .then(response => {
        if(response.status !== StatusCodes.OK)
            throw new Error(response.data.message);

        if(onSuccess)
            onSuccess(response.data);
    })
    .catch(error => {
        let errorMessage = null;

        let response = error.response;

        if(response)
        {
            let data = response.data;

            if(data)
                errorMessage = data.error || data.detail;

            if(!errorMessage)
                errorMessage = `Wystąpił błąd (kod błędu: ${response.status})`;
        }
        else
            errorMessage = error.message;

        if(onError)
            onError(errorMessage);
    });
}

export {
    API_URL,
    callApi
};
