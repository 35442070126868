import {useEffect, useState} from "react";

import Loading from "./Loading";

import TokenForm from "./TokenForm";

import PageContentContainer from "./PageContentContainer";

import {callApi} from "../utils";

import "../../css/home-page.css";

const PollDetails = props =>
{
    return (
        <div>
            <h3 className="lead">
                lub
            </h3>

            <a
                href="/poll"
                className="btn btn-link text-light fs-5"
            >
                Powróć do ankiety "{props.name}"
            </a>
        </div>
    );
};

const PageContentBody = props => {
    let pollName = props.pollName;

    return (
        <div>
            <div>
                <h1 className="cover-heading">
                    Uwierzytelnianie
                </h1>

                <p className="lead">
                    Przejdź do ankiety, podając przydzielony token.
                </p>

                <TokenForm/>
            </div>

            <div>
                {
                    pollName &&
                    <PollDetails
                        name={pollName}
                    />
                }
            </div>
        </div>
    );
};

const NavigationMenu = () => {
    return (
        <nav>
            <ul className="nav masthead-nav center">
                <li>
                    <a href="/results">
                        Wyniki
                    </a>
                </li>
            </ul>
        </nav>
    );
};

const HomePage = () => {
    const [loaded, setLoaded] = useState(false);
    const [details, setDetails] = useState(null);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState(null);

    useEffect(() => {
        callApi(
            "details", {
                method: "GET",
                onError: errorMessage => {
                    setLoaded(true);

                    setLoadingErrorMessage(errorMessage);
                },
                onSuccess: data => {
                    setLoaded(true);

                    setDetails(data);
                }
            }
        );
    }, []);

    let header = (
        <div className="container inner">
            <h3 className="masthead-brand">
                SecuWise

                {
                    loaded && details?.authorized &&
                    NavigationMenu()
                }
            </h3>
        </div>
    );

    return (
        <PageContentContainer
            header={header}
        >
            {
                loaded ? (
                    details ? (
                        <PageContentBody
                            pollName={details.poll_name}
                        />
                    ) : (
                        <h1 className="text-danger fw-bold">
                            {loadingErrorMessage}
                        </h1>
                    )
                ) : (
                    <Loading/>
                )
            }
        </PageContentContainer>
    );
};

const HomePageLink = () => {
    return (
        <a
            href="/"
            className="btn btn-link text-light"
        >
            Powrót do strony głównej
        </a>
    );
};

export {
    HomePage,
    HomePageLink
};
