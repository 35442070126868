import {useState, useEffect} from "react";

import Loading from "../Loading";

import PollFinished from "./PollFinished";

import PollHomePage from "./PollHomePage";

import QuestionGrid from "./QuestionGrid";

import {QuestionCard} from "./QuestionCard";

import PageContentContainer from "../PageContentContainer";

import {callApi} from "../../utils";

import "../../../css/poll/poll-page.css";

const GRID_QUESTION_CHILD_ID_DELIMETER = ".";

const encodeChildQuestionId = (parentQuestionId, childQuestionId) =>
    [parentQuestionId, childQuestionId].join(GRID_QUESTION_CHILD_ID_DELIMETER);

const getParentQuestionId = encodedQuestionId =>
    parseInt(
        encodedQuestionId.substring(
            0, encodedQuestionId.indexOf(GRID_QUESTION_CHILD_ID_DELIMETER)
        )
    );

function areQuestionConditionsMet(conditions, questions, answers)
{
    for(let condition of conditions)
    {
        let conditionChoiceIds = condition.choice_ids;
        let conditionQuestionId = condition.question_id;

        const choiceMeetsCondition = choiceId =>
            conditionChoiceIds.includes(choiceId);

        let conditionsAreMet = false;

        // if it's a grid question, check if any child's answer meets the condition
        if(questions[conditionQuestionId].child_questions)
            conditionsAreMet = Object.entries(answers).some(
                ([answerQuestionId, answerChoiceId]) =>
                    // can't check if `typeof answerQuestionId === "string"` because
                    // apprently an object's keys are always converted to strings
                    answerQuestionId.includes(GRID_QUESTION_CHILD_ID_DELIMETER) &&
                    getParentQuestionId(answerQuestionId) === conditionQuestionId &&
                    choiceMeetsCondition(answerChoiceId)
            );
        else
            conditionsAreMet = choiceMeetsCondition(
                answers[conditionQuestionId]
            );

        if(conditionsAreMet)
            return true;
    }

    return false;
}

const Questions = props => {
    let answers = props.answers;

    let questions = props.questions;

    let questionElements = questions.map((question, i) => {
        let conditions = question.conditions;

        if(conditions && !areQuestionConditionsMet(conditions, questions, answers))
            return null;

        question = {
            id: i,
            ...question
        };

        if(question.child_questions)
            return (
                <QuestionGrid
                    key={i}
                    question={question}
                    onAnswered={
                        (childQuestionId, choiceId) => props.onAnswered(
                            encodeChildQuestionId(question.id, childQuestionId), choiceId
                        )
                    }
                />
            );

        return (
            <QuestionCard
                key={i}
                question={question}
                onAnswered={
                    choiceId => props.onAnswered(
                        question.id, choiceId
                    )
                }
            />
        );
    });

    // remove null elements (questions with conditions that are not met)
    questionElements = questionElements.filter(element => element);

    return (
        <div className="questions-container">
            {questionElements}
        </div>
    );
};

function addMissingRequiredQuestionId(missingRequiredQuestionIds, questionId)
{
    if(!missingRequiredQuestionIds.includes(questionId))
        missingRequiredQuestionIds.push(questionId)
}

function removeMissingRequiredQuestionId(missingRequiredQuestionIds, questionId)
{
    let index = missingRequiredQuestionIds.indexOf(questionId);

    if(index !== -1)
        missingRequiredQuestionIds.splice(index, 1);
}

const getDecodedQuestionId = questionId =>
    typeof questionId === "string" ?
        getParentQuestionId(questionId) : questionId;

const handleEncodedChildQuestionIds = (parentQuestionId, childQuestions, onEncodedChildQuestionId) =>
    childQuestions.forEach(
        (_, i) => onEncodedChildQuestionId(
            encodeChildQuestionId(parentQuestionId, i)
        )
    );

// remove answers of questions whose conditions are no longer met
// (also calling this function recursively to ensure recursive conditions
// have been handled properly)
function removeAnswersOfQuestionsWithNoLongerMetConditions(
    questionId, questions, answers, openTextAnswers, missingRequiredQuestionIds, answerWasAdded
) {
    let decodedQuestionId = getDecodedQuestionId(questionId);

    questions.forEach((question, i) => {
        let conditions = question.conditions;

        // check if this question depends on the new/removed answer
        if(!conditions || !conditions.some(
            condition => condition.question_id === decodedQuestionId
        ))
            return;

        let questionIsRequired = question.required;

        let childQuestions = question.child_questions;

        if(areQuestionConditionsMet(conditions, questions, answers))
        {
            if(!answerWasAdded || !questionIsRequired)
                return;

            const maybeAddMissingRequiredQuestionId = answerQuestionId => {
                if(!(answerQuestionId in answers))
                    addMissingRequiredQuestionId(
                        missingRequiredQuestionIds, answerQuestionId
                    );
            };

            // if the answer has just been added and current question (dependent on the added answer)
            // is required and its conditions are met then we need to add the question id to
            // `missingRequiredQuestionIds` only if the question hasn't been answered 
            if(childQuestions)
                handleEncodedChildQuestionIds(
                    i, childQuestions, maybeAddMissingRequiredQuestionId
                );
            else
                maybeAddMissingRequiredQuestionId(i);

            return;
        }

        const maybeRemoveAnswer = answerQuestionId => {
            if(answerQuestionId in answers)
            {
                delete answers[answerQuestionId];

                // if it was an open-text answer, then remove it too
                if(answerQuestionId in openTextAnswers)
                    delete openTextAnswers[answerQuestionId];

                return true;
            }

            if(questionIsRequired)
                removeMissingRequiredQuestionId(
                    missingRequiredQuestionIds, answerQuestionId
                );

            return false;
        };

        if(childQuestions)
        {
            let removedAnswerQuestionIds = [];

            handleEncodedChildQuestionIds(
                i, childQuestions, answerQuestionId => {
                    if(maybeRemoveAnswer(answerQuestionId))
                        removedAnswerQuestionIds.push(answerQuestionId);
                }
            )

            removedAnswerQuestionIds.forEach(answerQuestionId =>
                removeAnswersOfQuestionsWithNoLongerMetConditions(
                    answerQuestionId, questions, answers, openTextAnswers, missingRequiredQuestionIds, false
                )
            );
        }
        else
        {
            maybeRemoveAnswer(i);

            removeAnswersOfQuestionsWithNoLongerMetConditions(
                i, questions, answers, openTextAnswers, missingRequiredQuestionIds, false
            );
        }
    });
}

const PollPage = () => {
    const [answers, setAnswers] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [pageData, setPageData] = useState(null);
    const [openTextAnswers, setOpenTextAnswers] = useState({});
    const [loadingErrorMessage, setLoadingErrorMessage] = useState(null);
    const [missingRequiredQuestionIds, setMissingRequiredQuestionIds] = useState([]);

    function fetchPageData({apiPath, method, data})
    {
        const resetStates = () => {
            setAnswers({});
            setOpenTextAnswers({});
        };

        resetStates();

        setLoaded(false);
        setPageData(null);
        setLoadingErrorMessage(null);
        setMissingRequiredQuestionIds([]);

        function onPageDataReceived(data)
        {
            let questions = data.questions;

            if(questions)
                setMissingRequiredQuestionIds(
                    questions.reduce((ids, question, i) => {    
                        // by default no conditions have been met
                        // so mark a required question's id as missing only
                        // if it has no conditions                    
                        if(question.required && !question.conditions)
                        {
                            let childQuestions = question.child_questions;

                            if(childQuestions)
                                ids = ids.concat(
                                    childQuestions.map(
                                        (_, j) => encodeChildQuestionId(i, j)
                                    )
                                );
                            else
                                ids.push(i);
                        }

                        return ids;
                    }, [])
                );

            resetStates();

            setLoaded(true);
            setPageData(data);
        }

        callApi(
            apiPath, {
                data: data,
                method: method,
                onError: errorMessage => {
                    setLoaded(true);

                    setLoadingErrorMessage(errorMessage);
                },
                onSuccess: onPageDataReceived
            }
        );
    }

    useEffect(() => {
        fetchPageData({
            apiPath: "",
            method: "GET"
        });
    }, []);

    // https://stackoverflow.com/a/68933242
    // Display confirmation dialog when user tries to leave the page
    useEffect(() => {
        // only add the confirmation dialog if a poll is being displayed
        // (and when there is at least one choice selected)
        if(!pageData || pageData.home_page || pageData.finished || !Object.keys(answers).length)
            return;

        const unloadCallback = event => {
            event.preventDefault();
            event.returnValue = "";

            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);

        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, [pageData, answers]);

    if(!loaded)
        return (
            <PageContentContainer>
                <Loading/>
            </PageContentContainer>
        );

    if(!pageData)
        return (
            <PageContentContainer>
                <h1 className="text-danger fw-bold">
                    {loadingErrorMessage}
                </h1>
            </PageContentContainer>
        );

    let questions = pageData.questions;

    // choiceId may be a string (for open-text answer)
    function onQuestionAnswered(questionId, choiceId)
    {
        let answerExists = questionId in answers;

        let answerIsOpenText = typeof choiceId === "string";

        let decodedQuestionId = getDecodedQuestionId(questionId);

        let questionIsRequired = questions[decodedQuestionId].required;

        const updateOpenTextAnswers = () =>
            // update as copy to trigger re-render
            setOpenTextAnswers({
                ...openTextAnswers
            });

        if(answerIsOpenText)
        {
            let text = choiceId.trim();

            // if e.g. just added whitespaces that were trimmed
            // and it resulted in the same answer
            if(text === openTextAnswers[questionId])
                return;

            if(text)
                openTextAnswers[questionId] = text;
            else
                delete openTextAnswers[questionId];

            // If there was an entry in `answers` (meaning: previous answer was non-empty),
            // but current text is empty, then remove the entry. If the text wasn't empty,
            // then there's no need to update `answers`.
            //
            // If there as no entry in `answers` (meaning: previous answer was empty),
            // then `answers` will be updated below.
            if(answerExists)
            {
                if(text)
                {
                    updateOpenTextAnswers();
                        
                    return;
                }

                delete answers[questionId];

                if(questionIsRequired)
                    addMissingRequiredQuestionId(
                        missingRequiredQuestionIds, questionId
                    );
            }
            else
                // set choice id so that it's properly set in `answers` below
                choiceId = 0;
        }

        // if it was an open-text answer and the answer existed, then it surely
        // doesn't exist now (since it was removed above)
        let shouldCreateAnswer = !answerIsOpenText || !answerExists;

        if(shouldCreateAnswer)
        {
            if(questionIsRequired)
                removeMissingRequiredQuestionId(
                    missingRequiredQuestionIds, questionId
                );

            answers[questionId] = choiceId;
        }

        removeAnswersOfQuestionsWithNoLongerMetConditions(
            questionId, questions, answers, openTextAnswers, missingRequiredQuestionIds, shouldCreateAnswer
        );    

        // update as copy to trigger re-render
        setAnswers({
            ...answers
        });

        updateOpenTextAnswers();

        // update as copy to trigger re-render
        // (it may have been updated above or in `removeAnswersOfQuestionsWithNoLongerMetConditions`)
        setMissingRequiredQuestionIds([
            ...missingRequiredQuestionIds
        ]);
    }

    let homePageContent = pageData.home_page;

    let isHomePage = Boolean(homePageContent);

    let finished = pageData.finished;

    const getPageElement = () => {
        let element = null;

        if(isHomePage)
            element = (
                <PollHomePage
                    pageContent={homePageContent}
                />
            );
        else if(finished)
            element = (
                <PollFinished/>
            );
        else
            element = (
                <Questions
                    answers={answers}
                    questions={questions}
                    onAnswered={onQuestionAnswered}
                />
            );

        return element;
    };

    let pageNum = pageData.page_num;

    const getNextPageRequestData = () => {
        let requestAnswers = [];
        let requestGridAnswers = {};

        Object.entries(answers).forEach(([questionId, choiceId]) => {
            const createAnswer = answerQuestionId => {
                let openTextAnswer = openTextAnswers[questionId];

                let answer = {
                    choice_id: choiceId,
                    question_id: answerQuestionId
                };

                if(openTextAnswer)
                    answer.open_text_answer = openTextAnswer;

                return answer;
            };

            // can't check if `typeof answerQuestionId === "string"` because
            // apprently an object's keys are always converted to strings
            if(questionId.includes(GRID_QUESTION_CHILD_ID_DELIMETER))
            {
                let [parentQuestionId, childQuestionId] = questionId.split(
                    GRID_QUESTION_CHILD_ID_DELIMETER
                );

                let childAnswers = null;

                if(!(parentQuestionId in requestGridAnswers))
                {
                    childAnswers = [];

                    requestGridAnswers[parentQuestionId] = {
                        answers: childAnswers
                    };
                }
                else
                    childAnswers = requestGridAnswers[parentQuestionId].answers;

                childAnswers.push(
                    createAnswer(childQuestionId)
                );

                return;
            }

            requestAnswers.push(
                createAnswer(questionId)
            );
        });

        requestGridAnswers = Object.entries(requestGridAnswers).map(
            ([questionId, gridAnswer]) => ({
                question_id: questionId,
                answers: gridAnswer.answers
            })
        );

        let data = {
            answers: requestAnswers,
            grid_answers: requestGridAnswers
        };

        return data;
    };

    return (
        <PageContentContainer>
            {getPageElement()}
            {
                !finished &&
                <button
                    onClick={() => {
                        fetchPageData({
                            data: getNextPageRequestData(),
                            method: "POST",
                            apiPath: "next-page"
                        });
                    }}
                    disabled={!isHomePage && missingRequiredQuestionIds.length}
                    className="btn btn-lg btn-primary"
                >
                    {
                        isHomePage ? "Rozpocznij ankietę" : (
                            pageData.max_page_num === pageNum ?
                                "Zakończ ankietę i prześlij odpowiedzi" :
                                "Zapisz odpowiedzi i przejdź na stronę " +
                                [pageNum + 1, pageData.max_page_num].join("/")
                        )
                    }
                </button>
            }
        </PageContentContainer>
    );
};

export default PollPage;
